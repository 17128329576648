<template>
    <div class="share-page"></div>
</template>

<script>
    import getStrParams from "../utils/getStrParams";

    export default {
        name: "sharePage",
        data() {
            return {
                paramsText: '',
                backUrl: '',
                stepText: ''
            }
        },
        methods: {
            getUrl() {
                const pageUrl = `https://redsw1.02lu.com/${decodeURIComponent(this.backUrl)}`
                return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf38126d81238fc63&redirect_uri=${pageUrl}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
            }
        },
        created() {
            this.backUrl = getStrParams.getStrParams(window.location.href.split('?')[1]).backUrl
            window.location.replace(this.getUrl())
        }
    }
</script>

<style scoped>

</style>